import React from 'react'
import AppNavbar from './common/AppNavbar'
import Footer from './layouts/Footer'


const Main = ({
    children
}) => {
    return (
        <>
            <AppNavbar />
            {children}
            <Footer />
        </>
    )
}

export default Main
