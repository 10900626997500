import { IconButton } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { allNavbarLinks, linkType } from '../../utils/constants/navbarConst';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default function AppDrawer() {
    const classes = useStyles();
    const drawerSlideInDirection = 'right'
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const GetListItem = navbarLink => (
        <ListItem component={Link} to={navbarLink.url} button key={navbarLink.label}>
            {
                navbarLink.icon ? <ListItemIcon>{navbarLink.icon}</ListItemIcon> : null
            }
            <ListItemText primary={navbarLink.label} />
        </ListItem>
    )

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {
                    allNavbarLinks
                        .filter(navbarLink => navbarLink.linkType === linkType.PUBLIC)
                        .map(navbarLink => GetListItem(navbarLink))
                }
            </List>
            <Divider />
            <List>
                {
                    allNavbarLinks
                        .filter(navbarLink => navbarLink.linkType === linkType.AUTH)
                        .map(navbarLink => GetListItem(navbarLink))
                }
            </List>
        </div>
    );

    return (
        <div>
            <>
                <IconButton aria-label='menu button' color='primary' onClick={toggleDrawer(drawerSlideInDirection, true)}><MenuIcon /></IconButton>
                <Drawer anchor={drawerSlideInDirection} open={state[drawerSlideInDirection]} onClose={toggleDrawer(drawerSlideInDirection, false)}>
                    {list(drawerSlideInDirection)}
                </Drawer>
            </>
        </div>
    );
}