import green from '@material-ui/core/colors/green';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        background: {
            default: '#F6FBFF',
            paper: '#FFFFFF'
        },
        primary: {
            main: '#4353E5',
        },
        secondary: {
            main: green[500],
        },
        text: {
            primary: '#4353E5',
            hint: '#8B8B8B',
        }
    },
});

export default theme;