import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import HomeScreen from './HomeScreen'
import Main from './Main'

const Router = () => {
    return (
        <BrowserRouter>
            <Main>
                <Switch>
                    <Route exact path='/' component={HomeScreen} />
                </Switch>
            </Main>
        </BrowserRouter>
    )
}

export default Router
