import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import CloudStorage from '../../assets/cloud_storage_illustration.svg';
import EasyMeetingsWithDots from '../../assets/easy_meetings_illustration_with_dots.svg';
import HalfDots from '../../assets/half_dots.svg';
import EasyMeetingsWithoutDots from '../../assets/meetings.svg';
import SecureMeetings from '../../assets/secure_meetings_illustration.svg';
import useWindowSize from '../common/hooks/useWindowSize';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(10, 0, 10, 0),
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(10, 3, 10, 3),
        },
        '& .MuiTypography-h2': {
            fontWeight: 700,
            fontSize: 48
        },
    },
    heroText: {
        fontWeight: 900,
        textAlign: 'center',
        fontSize: 72,
        letterSpacing: -3.5,
        [theme.breakpoints.down('md')]: {
            fontSize: 48,
        }
    },
    subText: {
        color: theme.palette.grey[700],
        marginTop: theme.spacing(2),
        lineHeight: '200%',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    featureRow: {
        paddingTop: theme.spacing(10),
    },
    oddIllustrationRow: {
        textAlign: 'right',
        paddingRight: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            paddingRight: 0,
            textAlign: 'center',
            '& img': {
                width: '100%'
            }
        }
    },
    oddDescriptionRow: {
        paddingLeft: theme.spacing(5),
        '& .MuiTypography-body1': {
            marginTop: theme.spacing(2),
            color: theme.palette.grey[700],
            lineHeight: '200%',
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            textAlign: 'center',
            '& .MuiTypography-h2': {
                fontSize: 32
            },
        }
    },
    evenIllustrationRow: {
        order: 2,
        paddingLeft: theme.spacing(5),
        [theme.breakpoints.down('md')]: {
            order: 2,
            paddingLeft: 0,
            textAlign: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            order: 1,
            paddingLeft: 0,
            textAlign: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            order: 1,
            paddingRight: 0,
            textAlign: 'center',
            '& img': {
                width: '100%'
            }
        }
    },
    evenDescriptionRow: {
        order: 1,
        textAlign: 'right',
        '& .MuiTypography-body1': {
            marginTop: theme.spacing(2),
            color: theme.palette.grey[700],
            lineHeight: '200%',
        },
        [theme.breakpoints.down('md')]: {
            order: 1,
            paddingRight: 0,
            textAlign: 'center',
            '& .MuiTypography-h2': {
                fontSize: 32
            },
        }
    },
    whiteInvertedCurveArea: {
        position: 'relative',

        '& img': {
            width: '100%',
            position: 'absolute',
            top: -1,
        }
    },
    rightDotsArea: {
        position: 'relative',
        '& img': {
            position: 'absolute',
            right: 0
        },
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    }
}))


const WhyChooseUsSection = () => {
    const classes = useStyles();
    const { width } = useWindowSize()

    return (
        <section>
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <Typography className={classes.heroText} variant='h1'>Why Choose Us</Typography>
                    <Typography className={classes.subText}>Everyday we work hard to make life of our clients better and happier.</Typography>
                </Grid>
            </Grid>

            {/* Feature 1 */}
            <Grid container className={clsx(classes.root, classes.featureRow,)}>
                <Grid item xs={12} md={6} className={clsx(classes.oddIllustrationRow)}>
                    <img src={(width > 1180) ? EasyMeetingsWithDots : EasyMeetingsWithoutDots} alt='easy meetings' />
                </Grid>
                <Grid item xs={12} md={6} className={classes.oddDescriptionRow}>
                    <Typography variant='h2'>Easy to <br />schedule meetings.</Typography>
                    <Typography >Instaconnect uncomplicates scheduling by only offering < br />times that work with your availability across all of your calendars.</Typography>
                </Grid>
            </Grid>

            {/* Feature 2 */}
            <Grid container className={clsx(classes.root, classes.featureRow)}>
                <Grid item xs={12} md={6} className={classes.evenIllustrationRow}>
                    <img src={CloudStorage} alt='cloud storage' />
                </Grid>
                <Grid item xs={12} md={6} className={classes.evenDescriptionRow}>
                    <Typography variant='h2'>Massive cloud <br />storage.</Typography>
                    <Typography >Massive storage for everyone. <br /> Store any amount of data. Retrieve it as often as you’d like.</Typography>
                </Grid>
            </Grid>

            {/* right dots */}
            <Box className={classes.rightDotsArea}>
                <img src={HalfDots} alt='half white dots' />
            </Box>


            {/* Feature 3 */}
            <Grid container className={clsx(classes.root, classes.featureRow)}>
                <Grid item xs={12} md={6} className={classes.oddIllustrationRow}>
                    <img src={SecureMeetings} alt='secure meetings' />
                </Grid>
                <Grid item xs={12} md={6} className={classes.oddDescriptionRow}>
                    <Typography variant='h2'>All meetings are <br />encrypted & secure.</Typography>
                    <Typography >Your meetings stay private and can only be seen <br /> by you and the person you're talking with.</Typography>
                </Grid>
            </Grid>
            {/* <Box className={classes.whiteInvertedCurveArea}>
                <img src={WhiteInvertedCurve} alt='white inverted curve' />
            </Box> */}
        </section>
    )
}

export default WhyChooseUsSection
